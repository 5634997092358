import React from "react";
import { graphql, Link as BaseLink } from "gatsby";
import styled from "styled-components";
import { Box, BoxExtendedProps, Heading } from "grommet";

import SanityImage from "@components/SanityImage";
import Paragraph from "@components/Paragraph";
import Button from "@components/Button";
import { getDesktopBreakpoint } from "@utils/Grommet";

export const cardWidthHD = 446;
export const cardWidthDesktop = 317;
export const cardHeightHD = 608;
export const cardHeightDesktop = 420;
export const cardPadHD = 15;
export const cardPadDesktop = 10;

const Container = styled(Box)`
  width: 100%;
  overflow: hidden;

  border-radius: 14px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    border-radius: 10px;
  }
`;

const ImageBox = styled(Box)`
  box-sizing: content-box;
  height: 215px;
  padding: 48px 0px 0px 42px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    height: 150px;
    padding: 34px 0px 0px 29px;
  }
`;

const ContentBox = styled(Box)`
  padding: 0px 42px 48px 42px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    padding: 0px 29px 34px 29px;
  }
`;

const Link = styled(BaseLink)`
  display: flex;
  flex-direction: column;
  height: 100%;

  &:hover {
    text-decoration: none;
  }
`;

type TProps = BoxExtendedProps & {
  solution?: Queries.SolutionCardFragment | null;
};

const SolutionCard = ({ solution, ...rest }: TProps) => {
  const seoImage = solution?.cardImage;

  return (
    <Container {...rest} background="white">
      <Link to={`/solutions/${solution?.slug?.current}`}>
        <ImageBox margin={{ bottom: "large" }}>
          <SanityImage
            {...seoImage?.image}
            alt={seoImage?.alt}
            objectfit="contain"
            width={405}
          />
        </ImageBox>

        <ContentBox align="start" justify="between" fill>
          <Box>
            <Heading as="span" level={2} margin={{ bottom: "xxsmall" }}>
              {solution?.title}
            </Heading>
            <Paragraph size="large" margin={{ bottom: "small" }}>
              {solution?.cardIntro}
            </Paragraph>
          </Box>

          <Button label="Learn more" background="brand" color="text" />
        </ContentBox>
      </Link>
    </Container>
  );
};

export default SolutionCard;

export const query = graphql`
  fragment SolutionCard on SanitySolution {
    _id
    id
    title
    cardIntro

    slug {
      current
    }

    cardImage {
      image {
        ...ImageWithPreview
      }
      alt
    }
  }
`;
