import React, { useCallback } from "react";
import styled from "styled-components";
import { Box, Grid as BaseGrid, Heading, Text } from "grommet";
import { useSwiper } from "swiper/react";

import SanityImage from "@components/SanityImage";
import Paragraph from "@components/Paragraph";
import Button from "@components/Button";
import { getDesktopBreakpoint, getMobileBreakpoint } from "@utils/Grommet";

const Grid = styled(BaseGrid)`
  width: 100%;
  max-width: 922px;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 9fr;
  align-items: center;

  @media (max-width: ${getDesktopBreakpoint}px) {
    max-width: 664px;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    max-width: 300px;

    grid-template-columns: 1fr;
    grid-template-rows: unset;
  }
`;

const GridImageItem = styled(Box)`
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: span 2;

  @media (max-width: ${getMobileBreakpoint}px) {
    grid-column-start: unset;
    grid-row-start: unset;
    grid-row-end: unset;
  }
`;

const ContentBox = styled(Box)`
  align-self: start;
`;

const ButtonBox = styled(Box)`
  @media (max-width: ${getMobileBreakpoint}px) {
    display: none;
  }
`;

type TProps = {
  index: number;
  proposition?: Queries.UniquePropositionsCarouselFragment | null;
};

const SlideCard = ({ index, proposition }: TProps) => {
  const richImage = proposition?.image;
  const swiper = useSwiper();

  const handleButton = useCallback(() => {
    swiper.slideNext();
  }, []);

  return (
    <Grid
      gap={{
        column: "large",
        row: "13px",
      }}
    >
      <Box>
        <Heading as="span" level={2} size="xlarge" color="brand">
          #{index}
        </Heading>
      </Box>

      <GridImageItem>
        <SanityImage {...richImage?.image} alt={richImage?.alt} />
      </GridImageItem>

      <ContentBox align="start">
        <Heading as="h3" level={2} margin={{ bottom: "xxsmall" }}>
          {proposition?.title}
        </Heading>

        <Paragraph size="large">{proposition?.description}</Paragraph>

        <ButtonBox margin={{ top: "small" }}>
          <Button
            label={
              <Text size="small" weight={500} color="text">
                Next
              </Text>
            }
            background="brand"
            onClick={handleButton}
          />
        </ButtonBox>
      </ContentBox>
    </Grid>
  );
};

export default SlideCard;
