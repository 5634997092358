import React, { useCallback, useRef } from "react";
import { graphql, Link, PageProps } from "gatsby";
import styled from "styled-components";
import { Box, Grid, Heading } from "grommet";
import { builder } from "gatsby-plugin-sanity-image";

import Seo from "@components/Seo";
import Paragraph from "@components/Paragraph";
import HubSpotSectionContent from "@components/HubSpotSectionContent";
import Button from "@components/Button";
import Text from "@components/Text";
import Svg from "@components/Svg";
import PageHero from "@components/PageHero";
import Section from "@components/Section";
import VideoPlayer from "@components/LazyVideoPlayer";
import ResponsiveContainerBox from "@components/ResponsiveContainerBox";
import SolutionCard, {
  cardWidthHD,
  cardWidthDesktop,
  cardPadHD,
  cardPadDesktop,
} from "@components/SolutionCard";
import CustomerStoriesCarousel from "@components/CustomerStoriesCarousel";
import UniquePropositionsCarousel from "@components/UniquePropositionsCarousel";
import Modal from "@components/Modal";
import {
  getMobileBreakpoint,
  getDesktopBreakpoint,
  ThickPaddedContainer,
} from "@utils/Grommet";
import useStateToggle from "@hooks/useStateToggle";
import { PlayIcon } from "@icons/Common";

const SolutionGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;

  max-width: ${cardWidthHD * 2 + cardPadHD * 2}px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    max-width: ${cardWidthDesktop * 2 + cardPadDesktop * 2}px;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    grid-template-columns: 1fr;
    grid-gap: 23px;

    max-width: ${cardWidthDesktop}px;
  }
`;

const HeroContent = styled(Box)`
  ${ThickPaddedContainer}

  padding-right: 50px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    padding-right: 25px;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    padding-left: 0px;
    padding-right: 0px;

    /* max-width: 350px; */
  }
`;

const HeroButtons = styled(Box)`
  margin-top: 35px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    margin-top: 18px;
    margin-bottom: 10px;
  }
`;

const FeaturesLink = styled(Link)`
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    display: none;
  }
`;

const IndexPage = ({
  data: { site, solutions },
  location,
}: PageProps<Queries.HomeQuery>) => {
  const hsRef = useRef<HTMLDivElement>(null);
  const [showVideoModal, openVideoModal, closeVideoModal] =
    useStateToggle(false);

  const handleHsScroll = useCallback(() => {
    hsRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [hsRef?.current]);

  const richVideo = site?.homeVideo;
  const videoHlsURL = `https://stream.mux.com/${richVideo?.video?.asset?.playbackId}.m3u8`;
  const videoThumb = `https://image.mux.com/${richVideo?.video?.asset?.playbackId}/thumbnail.jpg`;
  const videoPoster = richVideo?.customPoster
    ? builder.image(richVideo?.customPoster).width(700).url() ?? ""
    : videoThumb;

  return (
    <Box>
      <Seo
        title={site?.homeTitle ?? ""}
        description={site?.homeMetaDescription ?? undefined}
        ogImage={site?.homeHero?.image?.asset?.url}
        ogUrl={location?.href}
      />

      {showVideoModal && (
        <Modal onClose={closeVideoModal} height="auto" maxWidth="1300px">
          <VideoPlayer hlsURL={videoHlsURL} poster={videoPoster} />
        </Modal>
      )}

      <PageHero
        heightHD="604px"
        heightDesktop="430px"
        background="grey-10"
        seoImage={site?.homeHero}
      >
        <HeroContent align="start">
          <Heading level={1} color="brand" margin={{ bottom: "xxsmall" }}>
            {site?.homeTitle}
          </Heading>
          <Paragraph size="large">{site?.homeIntro}</Paragraph>

          <HeroButtons direction="row" gap="medium">
            <Button
              label="Get in touch"
              background="brand"
              color="grey-3"
              size="large"
              onClick={handleHsScroll}
            />

            <Button onClick={openVideoModal}>
              <Box direction="row" align="center" gap="xxsmall">
                <Svg fill="#EFEFEF" width={38}>
                  {PlayIcon}
                </Svg>
                <Text>Watch our film</Text>
              </Box>
            </Button>
          </HeroButtons>
        </HeroContent>
      </PageHero>

      <Section pad={{ top: "xlarge" }}>
        <ResponsiveContainerBox
          constrained="hd"
          padded="thick"
          margin={{ bottom: "large" }}
        >
          <Heading level={2}>Customer Stories</Heading>
        </ResponsiveContainerBox>

        <CustomerStoriesCarousel customerStories={site?.homeCustomerSories} />
      </Section>

      <Section
        constrained="hd"
        background="brand"
        pad={{ vertical: "xlarge" }}
        padded="thick"
      >
        <Heading level={2}>Learning Solutions</Heading>

        <Box align="center" margin={{ bottom: "medium" }}>
          <SolutionGrid margin={{ top: "large" }}>
            {solutions?.edges?.map((edge) => {
              const solution = edge?.node;

              return <SolutionCard key={solution?._id} solution={solution} />;
            })}
          </SolutionGrid>
        </Box>
      </Section>

      <Section constrained="hd" pad={{ vertical: "xlarge" }} padded="thick">
        <Box
          direction="row"
          justify="between"
          align="center"
          margin={{ bottom: "large" }}
        >
          <Heading level={2}>What makes us different</Heading>

          <FeaturesLink to="/features">View product features</FeaturesLink>
        </Box>

        <UniquePropositionsCarousel
          propositions={site?.homeUniquePropositions}
        />
      </Section>

      <Section ref={hsRef} constrained="hd" background="grey-3">
        <HubSpotSectionContent
          hsPortalId={site?.hsPortalId}
          hsFormId={site?.hsFormId}
        />
      </Section>
    </Box>
  );
};

export default IndexPage;

export const query = graphql`
  query Home {
    site: sanitySite {
      _id
      id
      logo {
        image {
          ...ImageWithPreview
        }
        alt
      }

      homeIntro
      homeTitle
      homeMetaDescription
      homeHero {
        ...PageHero
      }
      homeVideo {
        video {
          ...MuxVideo
        }

        customPoster {
          ...ImageWithPreview
        }
      }

      homeCustomerSories {
        ...CustomerStoriesCarousel
      }

      homeUniquePropositions {
        ...UniquePropositionsCarousel
      }

      ...HubSpotForm
    }

    solutions: allSanitySolution(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: order }
    ) {
      edges {
        node {
          ...SolutionCard
        }
      }
    }
  }
`;
