import React, { useCallback, useContext } from "react";
import { Layer, LayerExtendedProps, ResponsiveContext } from "grommet";
import styled from "styled-components";

import { crossIcon } from "@icons/Common";

import BaseBox from "@components/Box";
import Button from "@components/Button";
import Svg from "@components/Svg";

type TModalBoxProps = {
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
};

const Box = styled(BaseBox)<TModalBoxProps>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  max-height: ${({ maxHeight }) => maxHeight};
  max-width: ${({ maxWidth }) => maxWidth};
`;

export type TModalProps = LayerExtendedProps &
  TModalBoxProps & {
    onClose?: () => void;
    small?: boolean;
    closeProps?: {
      top?: string;
      right?: string;
      size?: string;
    };
  };

const Modal = ({
  children,
  onClose,
  maxWidth = "1024px",
  maxHeight = "768px",
  width = "90vw",
  height = "90vh",
  small = false,
  closeProps,
  ...rest
}: TModalProps): JSX.Element => {
  const size = useContext<string>(ResponsiveContext);
  const isPhone = size === "small";

  const actualCloseProps = small
    ? {
        top: "10px",
        right: "10px",
        size: "25px",
        ...closeProps,
      }
    : {
        top: "13px",
        right: "13px",
        size: "30px",
        ...closeProps,
      };

  const handleClose = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  return (
    <Layer
      responsive={isPhone ? true : false}
      onEsc={handleClose}
      onClickOutside={handleClose}
      {...rest}
    >
      <Box
        position="relative"
        width={isPhone ? "100%" : width}
        height={isPhone ? "100%" : height}
        maxWidth={isPhone ? "100%" : maxWidth}
        maxHeight={isPhone ? "100%" : maxHeight}
      >
        <Box fill overflow={{ vertical: "auto" }}>
          {children}
        </Box>

        <BaseBox
          position="absolute"
          zIndex={1}
          right={actualCloseProps?.right}
          top={actualCloseProps?.top}
        >
          <Button
            plain
            hoverIndicator
            onClick={handleClose}
            label={
              <Box pad="3px">
                <Svg width={actualCloseProps?.size} fill="#E0E0E0">
                  {crossIcon}
                </Svg>
              </Box>
            }
          />
        </BaseBox>
      </Box>
    </Layer>
  );
};

export default Modal;
