import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Box } from "grommet";
import { Swiper as BaseSwiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

import SlideCard from "./components/SlideCard";
import { getDesktopBreakpoint, getMobileBreakpoint } from "@utils/Grommet";

import "swiper/css";
import "swiper/css/pagination";

const Swiper = styled(BaseSwiper)`
  width: 100%;
  height: 355px;

  --swiper-pagination-bullet-vertical-gap: 15px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    height: 250px;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    height: 410px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }

  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme?.global?.colors?.brand};
  }
`;

type TProps = {
  propositions?: ReadonlyArray<
    Queries.UniquePropositionsCarouselFragment | undefined | null
  > | null;
};

const UniquePropositionsCarousel = ({ propositions }: TProps) => {
  return (
    <Box align="center" justify="start" fill="horizontal">
      <Swiper
        direction={"vertical"}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        grabCursor
        watchSlidesProgress
        speed={400}
        rewind
        spaceBetween={50}
        autoplay={{
          delay: 8000,
        }}
        loop
      >
        {propositions &&
          propositions.map((proposition, index) => (
            <SwiperSlide key={index}>
              <SlideCard index={index + 1} proposition={proposition} />
            </SwiperSlide>
          ))}
      </Swiper>
    </Box>
  );
};

export default UniquePropositionsCarousel;

export const query = graphql`
  fragment UniquePropositionsCarousel on SanityUniqueProposition {
    title
    description

    image {
      image {
        ...ImageWithPreview
      }
      alt
    }
  }
`;
